import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware'
import { ApiData } from '../api-client/api-client';
import { ModelParameters } from './common';
import { storage } from './storage';

interface StoredModels {
    [index: string]: ApiData;
}

export interface PermanentModelState {
    storedModels: StoredModels,
    insertModel: (params: ModelParameters, data: ApiData) => void,
    deleteModel: (params: ModelParameters) => void,
}

export const usePermanentModelState = create<PermanentModelState>()(
    persist(
        (set) => ({
            storedModels: {},
            insertModel: (params: ModelParameters, data: ApiData) => {
                set((state) => {
                    var models = state.storedModels;
                    models[JSON.stringify(params)] = data;
                    return { storedModels: models }
                })
            },
            deleteModel: (params: ModelParameters) => {
                set((state) => {
                    const models = state.storedModels;
                    delete models[JSON.stringify(params)];
                    return { storedModels: models }
                });
            }
        }),
        {
            name: 'permanent_turnout_storage',
            storage: createJSONStorage(() => storage),
        },
    )
)
