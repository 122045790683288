import { useEffect, useState } from "react";
import { ApiData, ReplacementOperation } from "../api-client/api-client";
import { Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

export function MaintenanceList({ apiData, turnoutName, year, setYear }: { apiData: ApiData, turnoutName: string, year: number, setYear: (year: number) => void }) {
    const [replacementOperations, setReplacementOperations] = useState(null as null | ReplacementOperation[]);
    useEffect(
        () => {
            setReplacementOperations(apiData.replacementOperations.filter(operation => operation.turnout === turnoutName));
        },
        [apiData, turnoutName]
    );

    if (replacementOperations === null) {
        return <></>;
    } else if (replacementOperations.length === 0) {
        return <Alert severity="info">{`No maintenance operations planned for ${turnoutName}`}</Alert>;
    } else {
        return (
            <TableContainer>
                <Table aria-label="maintenance operations table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 700 }}>Date</TableCell>
                            <TableCell sx={{ fontWeight: 700 }}>Component</TableCell>
                            <TableCell sx={{ fontWeight: 700 }}>Operation</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {replacementOperations.map(operation => {
                            const highlighted = operation.date === year;
                            const cellSx = { borderBottomColor: highlighted ? '#33ae33' : null, cursor: 'pointer' };
                            return (
                                <TableRow key={`${operation.component}-${operation.date}`} sx={{ background: highlighted ? '#33ae3319' : null }} onClick={() => setYear(operation.date)}>
                                    <TableCell sx={{ fontWeight: 700, ...cellSx }} component="th" scope="row">{operation.date}</TableCell>
                                    <TableCell sx={cellSx}>{operation.component}</TableCell>
                                    <TableCell sx={cellSx}>{operation.operation}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer >
        );
    };
}
