import { create } from "zustand";
import { ApiData } from "../api-client/api-client";
import { ModelParameters } from "./common";

export interface ModelState {
    params: ModelParameters,
    data: SuccessfullModelState | FailedApiState | LoadingApiState | NoState,
    setParams: (params: ModelParameters) => void,
    setData: (state: SuccessfullModelState | FailedApiState | LoadingApiState | NoState) => void,
}

export const useModelState = create<ModelState>()(
    (set) => ({
        params: { inflationRate: 2, degradeRate: 0, nOpMax: 20, maintenanceTrigger: 100 },
        data: { kind: "none" },
        setParams: async (params: ModelParameters) => {
            set((state) => ({ params: params }));
        },
        setData: (data: SuccessfullModelState | FailedApiState | LoadingApiState | NoState) => {
            set((state) => {
                if (state.data.kind !== "loading") {
                    return { data: data, lastData: state.data };
                } else {
                    return { data: data };
                };
            })
        }
    })
)

export interface SuccessfullModelState {
    kind: "successful",
    data: ApiData,
}

export interface FailedApiState {
    kind: "failed",
    message: string,
}

export interface LoadingApiState {
    kind: "loading",
}

export interface NoState {
    kind: "none",
}
