import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Alert, Container, Link } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ShieldIcon from '@mui/icons-material/Shield';
import {
  Link as RouterLink,
} from 'react-router-dom';
import LoginButton from '../../components/LoginButton';

function IndexPage() {
  return (
    <div className='flex flex-row fixed top-14 bottom-0 inset-x-0'>
      <main className='overflow-y-auto flex-1 p-4'>
        <Container maxWidth="md">
          <IndexPageInner />
        </Container>
      </main>
    </div>
  );
}

function IndexPageInner() {
  const { authState } = useOktaAuth();

  if (!authState) {
    return <div>Error: Unable to determine authentication state.</div>;
  }

  if (authState.isPending) {
    return <div>Loading...</div>;
  }

  if (!authState.isAuthenticated) {
    return (
      <Alert severity="info" icon={<ShieldIcon fontSize="inherit" />}>
        You are not logged in.<br />
        <LoginButton />
      </Alert>
    );
  }

  return (
    <Alert icon={<CheckIcon fontSize="inherit" />}>
      You are logged in.<br />
      <Link to="/model-parameters" component={RouterLink} variant="body2">Calculate some scenarios</Link>
    </Alert>
  );
}

export default IndexPage;
