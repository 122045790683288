import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Button } from '@mui/material';

function LoginButton() {
  const { authState, oktaAuth } = useOktaAuth();

  if (!authState) {
    return <div>Error: Unable to determine authentication state.</div>;
  }

  if (authState.isPending) {
    return <div>Loading...</div>;
  }

  const handleLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const handleLogout = async () => {
    await oktaAuth.signOut();
  };

  return authState.isAuthenticated ? (
    <Button variant="outlined" onClick={handleLogout}>Logout</Button>
  ) : (
    <Button variant="outlined" onClick={handleLogin}>Login</Button>
  );
};

export default LoginButton;
