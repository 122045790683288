export const TURNOUT_NAMES: string[] = [
    "B111-2 M2",
    "B112-1 M1",
    "B161-2 M2",
    "B162-1 M1",
    "B164-2 M6",
    "B165-1 M5",
    "B212-2 M10",
    "B213-2 M12",
    "B235-AG A1",
    "B236-AG A2",
    "B237-AG A3",
    "B238-AG A4",
    "B239-AG A5",
    "B481-2 M4",
    "B482-1 M3",
    "C011-1 M3",
    "C011-2 M4",
    "C012-1 M1",
    "C012-2 AG2",
    "C091-1 AG1",
    "C091-2 AG2",
    "C131-1 AG1",
    "C131-2 AG2",
    "C151-1 AG1",
    "C151-2 AG2",
    "C211-1 AG1",
    "C211-2 M2",
    "C214-1 M3",
    "C214-2 M4",
    "C221-1 M8",
    "C221-2 M9",
    "C222-1 M6",
    "C222-2 M7",
    "C231-A M2",
    "C231-R M1",
    "C232-A M4",
    "C232-R M3",
    "C411-1 M3",
    "C411-2 M4",
    "C412-1 M1",
    "C412-2 AG2",
    "C461-1 AG1",
    "C461-2 AG2",
    "C501-1 AG1",
    "C501-2 M2",
    "C502-1 M3",
    "C502-2 M4",
    "P233-0 MR0",
    "P233-1 MR1",
    "P233-2 MR2",
    "P233-3  MR3",
    "P233-4  MR4",
    "P233-5  MR5",
    "P233-6  MR6",
    "P233-7  MR7",
    "P233-8  MR8",
    "P233-8  MR9",
    "T113-1",
    "T163-1",
    "T166-1",
    "T483-1",
];

export const COMPONENT_NAMES: string[] = [
    "A",
    "B1",
    "C1",
    "F1",
    "PRA",
    "B2",
    "C2",
    "F2",
    "A1",
    "A2",
    "A3",
    "A4",
    "A5",
    "A6",
    "A7",
    "A8",
    "A9",
    "A10",
    "A11",
    "A12",
    "A13",
    "A14",
    "H1",
    "H2",
    "stock rail - deviated track",
    "stock rail - direct track",
    "shallow grooves - direct track",
    "shallow grooves - deviated track",
    "M1",
    "M1p",
    "M2",
    "M2p",
    "M3",
    "M3p",
    "M4",
    "M4p",
    "E1",
    "E5",
    "E4",
    "E7",
    "E2",
    "E3",
    "E6",
    "E8",
    "G1",
    "G2",
    "heel gap - direct track",
    "heel gap - deviated track",
    "Vertical wear crossing nose",
];

interface ComponentLimits {
    [index: string]: { max: number, min: number };
}

export const COMPONENT_LIMITS: ComponentLimits = {
    A: { max: 1445, min: 1433 },
    B1: { max: 1445, min: 1433 },
    C1: { max: 1445, min: 1433 },
    F1: { max: 1445, min: 1433 },
    PRA: { max: 1445, min: 1433 },
    B2: { max: 1445, min: 1433 },
    C2: { max: 1445, min: 1433 },
    F2: { max: 1445, min: 1433 },
    A1: { max: 1445, min: 1433 },
    A2: { max: 1445, min: 1433 },
    A3: { max: 1445, min: 1433 },
    A4: { max: 1445, min: 1433 },
    A5: { max: 1445, min: 1433 },
    A6: { max: 1445, min: 1433 },
    A7: { max: 1445, min: 1433 },
    A8: { max: 1445, min: 1433 },
    A9: { max: 1445, min: 1433 },
    A10: { max: 1445, min: 1433 },
    A11: { max: 1445, min: 1433 },
    A12: { max: 1445, min: 1433 },
    A13: { max: 1445, min: 1433 },
    A14: { max: 1445, min: 1433 },
    H1: { max: 78, min: 67 },
    H2: { max: 78, min: 67 },
    M1: { max: 1410, min: 1402 },
    M1p: { max: 1410, min: 1402 },
    M2: { max: 1410, min: 1402 },
    M2p: { max: 1410, min: 1402 },
    M3: { max: 1410, min: 1402 },
    M3p: { max: 1410, min: 1402 },
    M4: { max: 1410, min: 1402 },
    M4p: { max: 1410, min: 1402 },
    E1: { max: 1410, min: 1402 },
    E5: { max: 1410, min: 1402 },
    E4: { max: 1410, min: 1402 },
    E7: { max: 1410, min: 1402 },
    E2: { max: 1410, min: 1402 },
    E3: { max: 1410, min: 1402 },
    E6: { max: 1410, min: 1402 },
    E8: { max: 1410, min: 1402 },
    G1: { max: 47, min: 43 },
    G2: { max: 47, min: 43 },

}


COMPONENT_LIMITS['stock rail - deviated track'] = { max: 78, min: 67 };
COMPONENT_LIMITS['stock rail - direct track'] = { max: 78, min: 67 };
COMPONENT_LIMITS['shallow grooves - direct track'] = { max: 23, min: 18 };
COMPONENT_LIMITS['shallow grooves - deviated track'] = { max: 23, min: 18 };
COMPONENT_LIMITS['heel gap - direct track'] = { max: 5, min: 0 };
COMPONENT_LIMITS['heel gap - deviated track'] = { max: 5, min: 0 };
COMPONENT_LIMITS['Vertical wear crossing nose'] = { max: 6, min: 0 };