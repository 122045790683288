import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Dropdown } from 'semantic-ui-react';
import { Link } from 'react-router-dom';


interface UserInfo {
  email?: string;
}

const UserProfileDisplay: React.FC = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userEmail, setUserEmail] = useState<string | null>(null);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserEmail(null);
    } else {

      oktaAuth
        .getUser()
        .then((info: UserInfo) => {
          setUserEmail(info.email || '');
        })
        .catch((err) => {
          console.error('Failed to fetch user info:', err);
        });
    }
  }, [authState, oktaAuth]);

  const handleLogout = async () => {
    await oktaAuth.signOut();
  };

  return (
    <div className="flex items-center gap-4">
      {userEmail ? (
        <Dropdown
          text={userEmail || 'No Email'}
          pointing="top right"
          className="icon"
        >
          <Dropdown.Menu>
            <Dropdown.Item text="Logout" onClick={handleLogout} />
          </Dropdown.Menu>
        </Dropdown>



      ) : (
        <Link to="/profile" className="ts-app-bar-link">
          My Profile
        </Link>
      )}
    </div>
  );
};

export default UserProfileDisplay;
