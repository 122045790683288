import { Box, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import ModelParametersList from "../../components/ModelParametersList";
import CostPredictionInput from "../../components/CostPredictionInput";
import { useModelData } from "../../state/hooks";

function Index() {
    const apiState = useModelData();

    return (
        <Box sx={{ paddingTop: "3.5rem" }}>
            {apiState.kind === "loading" && <LinearProgress />}
            <Box sx={{ marginX: "auto" }}>
                < Typography variant="h4" padding={2}>Model parameters</Typography>
                <Grid container spacing={2} padding={2}>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={1}>
                            <Typography variant="h5" padding={2}>Stored Models</Typography>
                            <ModelParametersList withDelete={true} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Paper elevation={1}>
                            <Typography variant="h5" padding={2}>Add new model</Typography>
                            <CostPredictionInput disabled={apiState.kind === "loading"} />
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default Index;