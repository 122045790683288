import { Button, Container, FormControl, Grid, Input, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ApiData, getComponentsForTurnout } from "../../api-client/api-client";
import { useEffect, useState } from "react";
import { usePermanentModelState } from "../../state/permanent-model-state";
import { COMPONENT_LIMITS } from "../../api-client/static-data";

function DataEntryPage() {
    return (
        <main className="mt-20 pb-8">
            <Container maxWidth="md">
                <Typography variant="h4" sx={{ paddingBottom: 2 }}>Turnout measurement data entry</Typography>
                <Content />
            </Container>
        </main>
    );
}

function Content() {
    const data = usePermanentModelState((state) => Object.values(state.storedModels)[0]);

    if (data) {
        return <EntryForm apiData={data} />;
    } else {
        return <p>You have to load at least one model result before you can load data.</p>;
    }
}

interface InspectionData {
    turnoutName: string,
    year: number,
    measurementValues: ComponentValues,
    uploadDate: string,
}

interface ComponentValues {
    [index: string]: number | null,
}

async function saveInspectionData(data: InspectionData) {
    const tokenResponse = await fetch(
        'https://vossloh.okta-emea.com/oauth2/default/v1/token',
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams({ grant_type: "client_credential", scrope: "lambda:write" }),
        },
    );
    const tokenObject = await tokenResponse.json();
    console.log(tokenObject);
}

function EntryForm({ apiData }: { apiData: ApiData }) {
    const [year, setYear] = useState(new Date().getFullYear() as number | null);
    const [turnout, setTurnout] = useState("");
    const [components, setComponents] = useState({} as ComponentValues);
    const getComponent = (name: string) => components[name];
    const setComponent = (name: string, value: number | null) => {
        var newComponents = structuredClone(components);
        newComponents[name] = value;
        setComponents(newComponents);
    };

    /// (date, turnout, parameter)
    const [parameterNames, setParameterNames] = useState([] as string[]);
    useEffect(() => {
        setParameterNames(getComponentsForTurnout(apiData, turnout));
        return () => { };
    }, [turnout, apiData]);

    const save = async () => {
        const inspectionData: InspectionData = {
            turnoutName: turnout,
            year: year || new Date().getFullYear(),
            measurementValues: components,
            uploadDate: new Date().toISOString(),
        };
        await saveInspectionData(inspectionData);
    };

    return (
        <>
            <Typography variant="h5" sx={{ paddingBottom: 2 }}>New data</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                        <InputLabel htmlFor={`input-year`}>Year</InputLabel>
                        <Input
                            id={`input-year`}
                            value={year}
                            type="number"
                            onChange={(e) => {
                                const value = parseInt(e.target.value.trim());
                                if (!isNaN(value)) {
                                    setYear(Math.min(2100, value));
                                } else {
                                    setYear(null);
                                }
                            }}
                        />
                    </FormControl></Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel id="turnout-select-label">Turnout</InputLabel>
                        <Select
                            labelId="turnout-select-label"
                            id="turnout-select"
                            value={turnout}
                            label="Turnout"
                            onChange={(v) => setTurnout(v.target.value)}
                        >
                            {apiData.turnoutNames.map(turnoutName => (
                                <MenuItem key={turnoutName} value={turnoutName}>{turnoutName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} marginTop={4}>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: "bold" }}>Component</TableCell>
                                    <TableCell sx={{ fontWeight: "bold" }}>Good Value Range</TableCell>
                                    <TableCell sx={{ fontWeight: "bold" }}>Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {parameterNames.map(parameter => {
                                    const value = getComponent(parameter);
                                    const limits = COMPONENT_LIMITS[parameter] || { min: NaN, max: NaN };

                                    const shouldWarn = (!isNaN(limits.min) && !isNaN(limits.max) && value && (value < limits.min || value > limits.max)) || false;

                                    return (
                                        <TableRow key={parameter}>
                                            <TableCell>{parameter}</TableCell>
                                            <TableCell>{(!isNaN(limits.min) && !isNaN(limits.max)) ? `${limits.min}-${limits.max}` : '-'}</TableCell>
                                            <TableCell sx={{ width: "50%" }}>
                                                <input
                                                    type="text"
                                                    className="p-1 -m-1 focus:outline-none w-full block"
                                                    style={{ backgroundColor: shouldWarn ? "#ffaf00" : "transparent" }}
                                                    value={value || ""}
                                                    placeholder="value"
                                                    data-warn={shouldWarn}
                                                    onChange={(event) => {
                                                        const value = event.target.value;
                                                        const parsed = parseInt(value);
                                                        if (value && !isNaN(parsed)) {
                                                            setComponent(parameter, Math.max(parsed, 0))
                                                        } else {
                                                            setComponent(parameter, null)
                                                        }
                                                    }} />
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item>
                    <Button variant="contained" size="large" onClick={() => save()}>Save</Button>
                </Grid>
            </Grid >
        </>
    );
}

export default DataEntryPage;