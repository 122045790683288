import React from 'react';
import './App.css';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CostPrediction from './pages/cost-prediction/CostPrediction';
import { Route, Routes } from 'react-router-dom';
import Index from './pages/index/Index';
import 'leaflet/dist/leaflet.css'
import TurnoutMap from './pages/turnout-map/TurnoutMap';
import theme from './theme'
import TrackscapeAppBar from './components/TrackscapeAppBar';
import DataEntryPage from './pages/data-entry/DataEntry';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Security, LoginCallback } from '@okta/okta-react';
//import { OktaAuth } from '@okta/okta-auth-js';

import { oktaAuth } from './oktaConfig';
import IndexPage from './pages/home';
import Protected from './components/Protected';

function App() {
  const restoreOriginalUri = async (_: any, originalUri: string | undefined) => {
    if (originalUri) {
      window.location.replace(originalUri);
    } else {
      window.location.replace('/');
    }
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          <div className='h-screen max-h-screen w-screen bg-green-300 flex flex-col items-stretch align-items-start'>
            <TrackscapeAppBar />
            <Routes>
              <Route path="/" element={<IndexPage />} />
              <Route path="/model-parameters" element={<Protected><Index /></Protected>} />
              <Route path="/cost-prediction" element={<CostPrediction />} />
              <Route path="/turnout-map" element={<TurnoutMap />} />
              <Route path="/turnout-map/:year" element={<TurnoutMap />} />
              <Route path="/turnout-map/:year/:turnoutName" element={<TurnoutMap />} />
              <Route path="/inspection-data" element={<DataEntryPage />} />
              <Route path="/login/callback" element={<LoginCallback />} />
            </Routes>
          </div>
        </ThemeProvider>
      </LocalizationProvider>
    </Security>
  );
}

export default App;