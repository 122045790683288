import { Alert, Button, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import { ApiState, CostData, CostScenarioData, costScenarioMaximumValue, PlotData } from "../../api-client/api-client";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import ModelParametersList from "../../components/ModelParametersList";
import { Link } from "react-router-dom";
import { useModelData, useModelParams } from "../../state/hooks";
import { ModelParameters } from "../../state/common";
import { usePermanentModelState } from "../../state/permanent-model-state";

function CostPrediction() {
    const modelParameters = useModelParams();
    const apiState = useModelData();
    const oldApiState = { kind: "loading" } as ApiState;

    switch (apiState.kind) {
        case "successful":
            {
                if (modelParameters.nOpMax > 0) {
                    const costPredictionDataCSVUrl = `https://75eixbtfja4sa6ooomj5ay2n4a0uvrix.lambda-url.eu-central-1.on.aws/csvcosts?n_op_max=${modelParameters.nOpMax}&maintenance_trig=${Math.round(modelParameters.maintenanceTrigger / 100.)}&degrade_rate=${Math.round(modelParameters.degradeRate / 100.)}&inflation_rate=${Math.round(modelParameters.inflationRate)}`
                    const maxCost = Math.max(costScenarioMaximumValue(apiState.data.costData.directCostData), costScenarioMaximumValue(apiState.data.costData.groupedCostData));

                    return (
                        <div className='flex flex-row flex-1 grow-0 fixed top-14 left-0 right-0 bottom-0'>
                            <main className='overflow-y-auto flex-1 p-4'>
                                <Typography variant="h4" gutterBottom>
                                    Maintenance cost prediction
                                    <Button variant="contained" href={costPredictionDataCSVUrl} target="_blank" sx={{ color: "white", marginLeft: 4, fontWeight: 600 }}>Download CSV</Button>
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item lg={12}><CostPlot costData={apiState.data.costData} maxCost={maxCost} /></Grid>
                                    <Grid item lg={12}><CostComparisonPlot name="Material Cost" extractFn={(costData) => costData.materialCost} /></Grid>
                                    <Grid item lg={12}><CostComparisonPlot name="Labour Cost" extractFn={(costData) => costData.workingCost} /></Grid>
                                    <Grid item lg={12}><CostComparisonPlot name="Total Cost" extractFn={(costData) => costData.totalCost} /></Grid>
                                    {oldApiState.kind === "successful" && <Grid item lg={12}>
                                        <Typography variant="h4">{formatModelParameters(oldApiState.modelParameters)}</Typography>
                                        <CostPlot costData={oldApiState.data.costData} maxCost={maxCost} />
                                    </Grid>}
                                </Grid>
                            </main>
                            <aside className='ts-sidebar right-0 w-96 transition-all'>
                                <h2 className='block font-semibold text-gray-700 leading-[55px] text-xl pl-5 bg-gray-200 border-b border-b-gray-400'>Model parameters</h2>
                                <Button variant="outlined" sx={{ marginX: 1, marginTop: 1 }} component={Link} to="/">
                                    Manage stored results
                                </Button>
                                <ModelParametersList withDelete={false} />
                            </aside>
                        </div>
                    );
                } else {
                    return (
                        <div className='flex flex-row flex-1 grow-0 fixed top-14 left-0 right-0 bottom-0'>
                            <main className='overflow-y-auto flex-1 p-4'>
                                <Typography variant="h4" gutterBottom>
                                    Maintenance cost prediction
                                </Typography>
                                <Alert variant="filled" severity="info">
                                    There is no cost, as there are no maintenance operations.
                                    Please choose maintenance operations above 0 per year in the model parameters on the side to view costs.
                                </Alert>
                            </main>
                            <aside className='ts-sidebar right-0 w-96 transition-all'>
                                <h2 className='block font-semibold text-gray-700 leading-[55px] text-xl pl-5 bg-gray-200 border-b border-b-gray-400'>Model parameters</h2>
                                <Button variant="outlined" sx={{ marginX: 1, marginTop: 1 }} component={Link} to="/">
                                    Manage stored results
                                </Button>
                                <ModelParametersList withDelete={false} />
                            </aside>
                        </div>
                    );
                }
            }
        case "loading":
            return (
                <div className='flex flex-row flex-1 grow-0 fixed top-14 left-0 right-0 bottom-0'>
                    <main className='overflow-y-auto flex-1'>
                        <LinearProgress />
                    </main>
                    <aside className='ts-sidebar right-0 w-96 transition-all'>
                        <h2 className='block font-semibold text-gray-700 leading-[55px] text-xl pl-5 bg-gray-200 border-b border-b-gray-400'>Model parameters</h2>
                        <Button variant="outlined" sx={{ marginX: 1, marginTop: 1 }} component={Link} to="/">
                            Manage stored results
                        </Button>
                        <ModelParametersList withDelete={false} />
                    </aside>
                </div>
            );
        case "failed":
            return (
                <div className='flex flex-row flex-1 grow-0 fixed top-14 left-0 right-0 bottom-0'>
                    <main className='overflow-y-auto flex-1 p-4'>
                        <p>Failed: {apiState.message}</p>
                    </main>
                    <aside className='ts-sidebar right-0 w-96 transition-all'>
                        <h2 className='block font-semibold text-gray-700 leading-[55px] text-xl pl-5 bg-gray-200 border-b border-b-gray-400'>Model parameters</h2>
                        <Button variant="outlined" sx={{ marginX: 1, marginTop: 1 }} component={Link} to="/">
                            Manage stored results
                        </Button>
                        <ModelParametersList withDelete={false} />
                    </aside>
                </div>
            );
        case "none":
            return (
                <div className='flex flex-row flex-1 grow-0 fixed top-14 left-0 right-0 bottom-0'>
                    <main className='overflow-y-auto flex-1'>
                        <Alert severity="info">Please select a model result from the right bar or calculate one</Alert>
                    </main>
                    <aside className='ts-sidebar right-0 w-96 transition-all'>
                        <h2 className='block font-semibold text-gray-700 leading-[55px] text-xl pl-5 bg-gray-200 border-b border-b-gray-400'>Model parameters</h2>
                        <Button variant="outlined" sx={{ marginX: 1, marginTop: 1 }} component={Link} to="/">
                            Manage stored results
                        </Button>
                        <ModelParametersList withDelete={false} />
                    </aside>
                </div>
            );
    }
}

function formatModelParameters(params: ModelParameters): string {
    return `infl. rate:${params.inflationRate}% degr. rate: ${params.degradeRate}% n ops. max: ${params.nOpMax} m.trig.: ${params.maintenanceTrigger}%`;
}

function CostPlot({ costData, maxCost }: { costData: CostData, maxCost: number }) {
    const transformedData = Array.from(costData.directCostData.materialCost.time).map((time, i) => {
        return {
            time: time,
            directMaterialCost: costData.directCostData.materialCost.values[i].toFixed(2),
            directLaborCost: costData.directCostData.workingCost.values[i].toFixed(2),
            directTotalCost: costData.directCostData.totalCost.values[i].toFixed(2),
            groupedMaterialCost: costData.groupedCostData.materialCost.values[i].toFixed(2),
            groupedLaborCost: costData.groupedCostData.workingCost.values[i].toFixed(2),
            groupedTotalCost: costData.groupedCostData.totalCost.values[i].toFixed(2),
        };
    });
    return (
        <Paper elevation={1} sx={{ p: 1, mt: 2 }}>
            <ResponsiveContainer height={600}>
                <LineChart data={transformedData}>
                    <CartesianGrid stroke="1" />
                    <XAxis
                        dataKey="time"
                        allowDuplicatedCategory={true}
                        type="number"
                        domain={['dataMin', 'dataMax']}
                        ticks={transformedData.map(data => data.time).filter(v => v % 5 === 0).filter(onlyUnique)}
                        interval="equidistantPreserveStart"
                    />
                    <YAxis
                        domain={[0, maxCost]}
                        tickFormatter={v => v.toFixed(0)}
                        interval={"preserveStart"}
                        type="number"
                    />
                    <Tooltip />
                    <Legend />
                    <Line type="linear" dataKey="directMaterialCost" name="Material" stroke="#63b6c9" legendType="plainline" strokeWidth={2} animationDuration={500} />
                    <Line type="linear" dataKey="directLaborCost" name="Labor" stroke="#9144ad" legendType="plainline" strokeWidth={2} animationDuration={500} />
                    <Line type="linear" dataKey="directTotalCost" name="Total" stroke="#1c1c1c" legendType="plainline" strokeWidth={2} animationDuration={500} />
                    <Line type="linear" dataKey="groupedMaterialCost" name="Grouped Material" stroke="#63b6c9" legendType="plainline" strokeWidth={2} animationDuration={1000} strokeDasharray="8 4" />
                    <Line type="linear" dataKey="groupedLaborCost" name="Grouped Labor" stroke="#9144ad" legendType="plainline" strokeWidth={2} animationDuration={1000} strokeDasharray="8 4" />
                    <Line type="linear" dataKey="groupedTotalCost" name="Grouped Total" stroke="#1c1c1c" legendType="plainline" strokeWidth={2} animationDuration={1000} strokeDasharray="8 4" />
                </LineChart>
            </ResponsiveContainer>
        </Paper>
    )
}

function onlyUnique(value: any, index: number, array: any[]) {
    return array.indexOf(value) === index;
}

function CostComparisonPlot({ name, extractFn }: { name: string, extractFn: (costData: CostScenarioData) => PlotData }) {
    const colors = ["#ef4444", "#65a30d", "#059669", "#0891b2", "#7c3aed", "#db2777"];

    const costData = usePermanentModelState((state) => Object.keys(state.storedModels).map((key) => {
        return {
            paramsStr: key,
            direct: extractFn(state.storedModels[key].costData.directCostData),
            grouped: extractFn(state.storedModels[key].costData.groupedCostData),
        };
    }));

    const maxCost = Math.max(...costData.map(v => Math.max(...v.direct.values, ...v.grouped.values)));

    const transformedData = Array.from(costData[0].direct.time).map((time, i) => {
        var o: any = { time: time };

        for (const { paramsStr, direct, grouped } of costData) {
            o[paramsStr + '; direct'] = direct.values[i].toFixed(2);
            o[paramsStr + '; grouped'] = grouped.values[i].toFixed(2);
        }

        return o;
    });

    console.log(transformedData);

    return (
        <Paper elevation={1} sx={{ p: 1, mt: 2 }}>
            <Typography variant="h4" gutterBottom>{name}</Typography>
            <ResponsiveContainer height={600}>
                <LineChart data={transformedData}>
                    <CartesianGrid stroke="1" />
                    <XAxis
                        dataKey="time"
                        allowDuplicatedCategory={true}
                        type="number"
                        domain={['dataMin', 'dataMax']}
                        ticks={transformedData.map(data => data.time).filter(v => v % 5 === 0).filter(onlyUnique)}
                        interval="equidistantPreserveStart"
                    />
                    <YAxis
                        domain={[0, maxCost]}
                        tickFormatter={v => v.toFixed(0)}
                        interval={"preserveStart"}
                        type="number"
                    />
                    <Tooltip />
                    <Legend />
                    {costData.map(({ paramsStr }, i) => {
                        const params = JSON.parse(paramsStr) as ModelParameters;
                        const formatted = `${params.inflationRate}%; ${params.degradeRate}%; ${params.nOpMax}; ${params.maintenanceTrigger}%`;
                        return <Line type="linear" key={paramsStr + '; direct'} dataKey={paramsStr + '; direct'} name={formatted + " direct"} stroke={colors[i % colors.length]} legendType="plainline" strokeWidth={2} animationDuration={500} />;
                    })}
                    {costData.map(({ paramsStr }, i) => {
                        const params = JSON.parse(paramsStr) as ModelParameters;
                        const formatted = `${params.inflationRate}%; ${params.degradeRate}%; ${params.nOpMax}; ${params.maintenanceTrigger}%`;
                        return <Line type="linear" key={paramsStr + '; grouped'} dataKey={paramsStr + '; grouped'} name={formatted + " grouped"} stroke={colors[i % colors.length]} legendType="plainline" strokeWidth={2} animationDuration={500} strokeDasharray="8 4" />;
                    })}
                </LineChart>
            </ResponsiveContainer>
        </Paper>
    )
}

export default CostPrediction;