// src/config/oktaConfig.js

import { OktaAuth } from '@okta/okta-auth-js';

// Your Okta configuration
const oktaConfig = {
  issuer: 'https://vossloh.okta-emea.com/oauth2/default',
  clientId: '0oae0wyi8nhtrNTP90i7',
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'email', 'lambda:write'],
};

// Export the Okta configuration object and OktaAuth instance
export default oktaConfig;
export const oktaAuth = new OktaAuth(oktaConfig);

