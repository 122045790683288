import { Alert, Box, Button, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, Input, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Paper, Radio, RadioGroup, Slider, Switch, Typography } from "@mui/material";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import markerIconDark from "./atom-icon-poi-poi-active.svg";
import markerIconGreen from "./atom-icon-poi-poi-active-green.svg";
import markerIconYellow from "./atom-icon-poi-poi-active-yellow.svg";
import markerIconRed from "./atom-icon-poi-poi-active-red.svg";
import markerShadow from "./marker-shadow.png";
import L, { LatLngTuple } from "leaflet";
import { useCallback, useEffect, useMemo, useState } from "react";
import { WearPredictionList } from "../wear-prediction/WearPrediction";
import iconNavigationClose from './atom-icon-navigation-close.svg';
import { MaintenanceList } from "../../components/MaintenanceList";
import { Link, useNavigate, useParams } from "react-router-dom";
import ModelParametersList from "../../components/ModelParametersList";
import { useModelData, useModelParams } from "../../state/hooks";
import { getTurnoutId } from "../../api-client/api-client";

var darkIcon = L.icon({
    iconUrl: markerIconDark,
    shadowUrl: markerShadow,

    iconSize: [25, 41],
    shadowSize: [41, 41],
    iconAnchor: [13, 41],
    shadowAnchor: [13, 41],
    popupAnchor: [0, -40],
});

var greenIcon = L.icon({
    iconUrl: markerIconGreen,
    shadowUrl: markerShadow,

    iconSize: [25, 41],
    shadowSize: [41, 41],
    iconAnchor: [13, 41],
    shadowAnchor: [13, 41],
    popupAnchor: [0, -40],
});

var yellowIcon = L.icon({
    iconUrl: markerIconYellow,
    shadowUrl: markerShadow,

    iconSize: [25, 41],
    shadowSize: [41, 41],
    iconAnchor: [13, 41],
    shadowAnchor: [13, 41],
    popupAnchor: [0, -40],
});

var redIcon = L.icon({
    iconUrl: markerIconRed,
    shadowUrl: markerShadow,

    iconSize: [25, 41],
    shadowSize: [41, 41],
    iconAnchor: [13, 41],
    shadowAnchor: [13, 41],
    popupAnchor: [0, -40],
});

type ProblemSource = "problem_count" | "next_maintenance";
type SampleTime = "before_maintenance" | "after_maintenance";

function TurnoutMap() {

    const [problemSource, setProblemSource] = useState("problem_count" as ProblemSource);
    const [sampleTime, setSampleTime] = useState("before_maintenance" as SampleTime);
    const navigate = useNavigate();
    const params = useParams() as { turnoutName: string | undefined, year: string | undefined };
    const year: number = parseInt(params.year || "0");
    const turnoutName: string | null = params.turnoutName || null;
    const [zoom, setZoom] = useState(13);
    const modelParameters = useModelParams();
    const apiState = useModelData();
    const [demoMode, setDemoMode] = useState(true);
    const offset = useMemo(() => demoMode ? [30.0995, -15.0055] : [0.0, 0.0], [demoMode])

    const setYear = useCallback((year: number) => {
        if (turnoutName === null) {
            navigate(`/turnout-map/${year}`);
        } else {
            navigate(`/turnout-map/${year}/${turnoutName}`);
        }
    }, [turnoutName, navigate]);

    const setTurnoutName = (name: string | null) => {
        if (name === null) {
            navigate(`/turnout-map/${year}`);
        } else {
            navigate(`/turnout-map/${year}/${name}`);
        }
    };

    const [center, setCenter] = useState(null as LatLngTuple | null);
    const [minMaxYear, setMinMaxYar] = useState(null as number[] | null);

    const parametersAside = (
        <aside className='ts-sidebar right-0 w-72 transition-all'>
            <h2 className='block font-semibold text-gray-700 leading-[55px] text-xl pl-5 bg-gray-200 border-b border-b-gray-400'>Model parameters</h2>
            <Button variant="outlined" sx={{ marginX: 1, marginTop: 1 }} component={Link} to="/">
                Manage stored results
            </Button>
            <ModelParametersList withDelete={false} />

            <h2 className='block font-semibold text-gray-700 leading-[55px] text-xl pl-5 bg-gray-200 border-b border-b-gray-400'>Map</h2>
            <Paper elevation={0} sx={{ p: 2 }}>
                <ProblemSourceControl problemSource={problemSource} setProblemSource={setProblemSource} />
                <SampleTimeControl sampleTime={sampleTime} setSampleTime={setSampleTime} />
                <FormGroup>
                    <FormControlLabel control={<Switch checked={demoMode} onChange={() => setDemoMode(!demoMode)} />} label="Demo mode" />
                </FormGroup>
            </Paper>
        </aside>
    );

    var maintenanceAside = <></>;
    var mapAdditional = <></>;

    useEffect(() => {
        if (apiState.kind === "successful" && !(year > 0)) {
            const year = apiState.data.dates[0];
            setYear(year);
        }
        return () => { };
    }, [apiState, year, setYear]);

    if (apiState.kind === "successful" && center === null) {
        const apiData = apiState.data;
        const turnoutId = getTurnoutId(apiState.data, turnoutName || "");

        let newCenter = [NaN, NaN] as LatLngTuple;

        if (turnoutId >= 0) {
            newCenter = [
                apiData.latitudes[turnoutId] + offset[0],
                // small hack as the sidebar takes much screen and the center of the map is not the center anymore
                // works in Rabat, will work bad in more northern or southern places
                apiData.longitudes[turnoutId] - 0.002 + offset[1]
            ] as LatLngTuple;
            setZoom(18);
        } else {
            newCenter = [
                apiData.latitudes.reduce((acc, c) => acc + c, 0) / apiData.latitudes.length + offset[0],
                apiData.longitudes.reduce((acc, c) => acc + c, 0) / apiData.longitudes.length + offset[1],
            ] as LatLngTuple;
        }

        if (!isNaN(newCenter[0]) && !isNaN(newCenter[1])) {
            setCenter(newCenter);
        }
    }

    var markers = <></>

    switch (apiState.kind) {
        case "successful":
            {
                const apiData = apiState.data;

                const minYear = Math.min(...apiData.dates);
                const maxYear = Math.max(...apiData.dates);

                if (minMaxYear === null || minMaxYear[0] !== minYear || minMaxYear[1] !== maxYear) {
                    setMinMaxYar([minYear, maxYear]);
                }

                if (year === 0 && isFinite(minYear)) {
                    setYear(minYear);
                }

                var selectedData: number[][] = [];
                if (problemSource === "next_maintenance") {
                    if (sampleTime === "before_maintenance") {
                        selectedData = apiData.criticality_bef;
                    } else if (sampleTime === "after_maintenance") {
                        selectedData = apiData.criticality_aft;
                    }
                } else if (problemSource === "problem_count") {
                    if (sampleTime === "before_maintenance") {
                        selectedData = apiData.criticite_bef;
                    } else if (sampleTime === "after_maintenance") {
                        selectedData = apiData.criticite_aft;
                    }
                }

                console.log(selectedData);

                const turnoutId = getTurnoutId(apiData, turnoutName || "");

                const turnoutOperationsCSVUrl = `https://75eixbtfja4sa6ooomj5ay2n4a0uvrix.lambda-url.eu-central-1.on.aws/csvoperationlists?n_op_max=${modelParameters.nOpMax}&maintenance_trig=${Math.round(modelParameters.maintenanceTrigger / 100.)}&degrade_rate=${Math.round(modelParameters.degradeRate / 100.)}&inflation_rate=${Math.round(modelParameters.inflationRate)}&turnout=${turnoutName}`
                const maintenanceDataCSVUrl = `https://75eixbtfja4sa6ooomj5ay2n4a0uvrix.lambda-url.eu-central-1.on.aws/csvturnout?n_op_max=${modelParameters.nOpMax}&maintenance_trig=${Math.round(modelParameters.maintenanceTrigger / 100.)}&degrade_rate=${Math.round(modelParameters.degradeRate / 100.)}&inflation_rate=${Math.round(modelParameters.inflationRate)}&turnout=${turnoutId}`

                maintenanceAside = turnoutName !== null ?
                    <aside className="shadow-2xl flex flex-col bg-white ts-sidebar absolute left-0 top-0 bottom-0 translate-x-0 flex-1 w-[48rem] transition-transform duration-300 s z-[1006] overflow-x-hidden overflow-y-auto" >
                        <h2 className='items-center font-semibold text-gray-700 leading-[55px] text-xl pl-5 bg-gray-200 border-b border-b-gray-400'>
                            Turnout {turnoutName}
                            <button className="p-4 float-right" onClick={() => setTurnoutName(null)}>
                                <img src={iconNavigationClose} alt="close" width={24} height={24} />
                            </button>
                        </h2>
                        <div className="overflow-y-scroll">
                            <Button variant="contained" href={turnoutOperationsCSVUrl} target="_blank" sx={{ color: "white", marginX: 4, marginY: 2, fontWeight: 600 }}>Download maintenance operations as CSV</Button>
                            <MaintenanceList apiData={apiData} turnoutName={turnoutName} year={year} setYear={setYear} />
                            <Button variant="contained" href={maintenanceDataCSVUrl} target="_blank" sx={{ color: "white", marginX: 4, marginY: 2, fontWeight: 600 }}>Download Values as CSV</Button>
                            {turnoutName !== null && <WearPredictionList apiData={apiData} turnoutName={turnoutName} year={year} />}
                        </div>
                    </aside >
                    :
                    <aside className="shadow-2xl flex flex-col bg-white ts-sidebar absolute left-0 top-0 bottom-0 -translate-x-full  flex-1 w-[48rem] transition-transform duration-300 z-[1006]">
                        <h2 className='items-center font-semibold text-gray-700 leading-[55px] text-xl pl-5 bg-gray-200 border-b border-b-gray-400'>
                            Turnout {turnoutName}
                            <button className="p-4 float-right" onClick={() => setTurnoutName(null)}>
                                <img src={iconNavigationClose} alt="close" width={24} height={24} />
                            </button>
                        </h2>
                    </aside>
                    ;

                if (selectedData !== null && selectedData.length > 0) {
                    markers = <>{apiData.turnoutNames.map((turnoutName, turnoutId) => {
                        const value = selectedData[turnoutId][year - minYear];
                        let icon = [greenIcon, yellowIcon, redIcon, darkIcon][value > 3 ? 3 : value];

                        return <Marker key={turnoutName} position={[apiData.latitudes[turnoutId] + offset[0], apiData.longitudes[turnoutId] + offset[1]]} icon={icon} eventHandlers={{
                            click: () => {
                                setTurnoutName(turnoutName);
                            },
                        }}>
                        </Marker>;
                    })}</>;
                }

                break;
            }

        case "loading": {
            mapAdditional = <div className="absolute top-0 left-0 right-0 z-[1010] backdrop-blur bg-white/10 " style={{ height: 'calc(100vh - 64px)' }}>
                <LinearProgress />
            </div>;
            break;
        }

        case "failed": {
            mapAdditional = <Alert severity="error">Loading data from API failed: {apiState.message}</Alert>;
            break;
        }

        case "none": {
            mapAdditional = <Alert severity="info">Please select a model result from the right bar or calculate one</Alert>;
            break;
        }
    }

    function MapClickHandler() {
        useMapEvents({
            click: () => setTurnoutName(null),
        })

        return <></>;
    }

    return (
        <div className='flex flex-row flex-1 grow-0 fixed top-14 left-0 right-0 bottom-0'>
            {maintenanceAside}
            <main className='overflow-y-auto flex-1'>
                <Box sx={{ position: 'relative' }}>
                    {mapAdditional}
                    {(center !== null && year !== 0) && <>
                        <MapContainer center={center} zoom={zoom} scrollWheelZoom={true} style={{ height: 'calc(100vh - 64px)' }}>
                            <MapClickHandler />
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            {markers}
                        </MapContainer>
                        <Paper elevation={3} sx={{ p: 2, zIndex: 1000, position: 'absolute', left: 8, bottom: 8, background: 'rgba(255, 255, 255, 0.4)', backdropFilter: 'blur(2px)' }}>
                            <MapLegend problemSource={problemSource} />
                        </Paper>
                    </>}
                    {minMaxYear !== null && <YearControl year={year} setYear={setYear} minYear={minMaxYear[0]} maxYear={minMaxYear[1]} />}
                </Box >

            </main>
            {parametersAside}
        </div >
    );

}

function ProblemSourceControl({ problemSource, setProblemSource }: { problemSource: ProblemSource, setProblemSource: (problemSource: ProblemSource) => void }) {
    return <FormControl>
        <FormLabel id="severity-source-radio-buttons-group-label">Severity marker</FormLabel>
        <RadioGroup
            aria-labelledby="severity-source-radio-buttons-group-label"
            defaultValue="problem_count"
            name="severity-source-radio-buttons-group"
            value={problemSource}
            onChange={function (event) {
                setProblemSource(event.target.value as ProblemSource)
            }}
        >
            <FormControlLabel value="problem_count" control={<Radio />} label="Critical value count" />
            <FormControlLabel value="next_maintenance" control={<Radio />} label="Next maintenance" />
        </RadioGroup>
    </FormControl>;
}

function SampleTimeControl({ sampleTime, setSampleTime }: { sampleTime: SampleTime, setSampleTime: (sampleTime: SampleTime) => void }) {
    return <FormControl>
        <FormLabel id="after-maintenance-radio-buttons-group-label">Sample time</FormLabel>
        <RadioGroup
            aria-labelledby="after-maintenance-radio-buttons-group-label"
            defaultValue="before"
            name="after-maintenance-radio-buttons-group"
            value={sampleTime}
            onChange={function (event) {
                setSampleTime(event.target.value as SampleTime)
            }}
        >
            <FormControlLabel value="before_maintenance" control={<Radio />} label="Before maintenance" />
            <FormControlLabel value="after_maintenance" control={<Radio />} label="After maintenance" />
        </RadioGroup>
    </FormControl>;
}

function YearControl({ year, setYear, minYear, maxYear }: { year: number, setYear: (year: number) => void, minYear: number, maxYear: number }) {
    const ticks = Array.from({ length: maxYear - minYear + 1 }, (_, i) => i + minYear).filter(y => y % 5 === 0);

    return <div className="absolute right-4 top-4 w-96 z-[1004] bg-white rounded shadow-md px-4 py-2">
        <Typography id="year-slider">Year</Typography>
        <Grid container spacing={4}>
            <Grid item xs>
                <Slider
                    aria-label="Date"
                    defaultValue={minYear}
                    getAriaValueText={function (value: number) { return `${value}`; }}
                    step={1} min={minYear} max={maxYear}
                    marks={ticks.map((value) => { return { label: value.toString(), value: value, } })}
                    valueLabelDisplay="auto"
                    value={year}
                    onChange={(event, value, activeThumb) => setYear(value as number)}
                    track={false}
                    aria-labelledby="year-slider"
                    sx={{ ml: 1 }}
                />
            </Grid>
            <Grid item>
                <Input
                    value={year}
                    onChange={(event) => setYear(parseInt(event.target.value))}
                    inputProps={{ step: 1, min: 2020, max: 2050, type: "number", "aria-labelledby": "year-slider" }}
                />
            </Grid>
        </Grid>
    </div>;
}

function MapLegend({ problemSource }: { problemSource: ProblemSource }) {
    function listItem({ icon, alt, text }: { icon: string, alt: string, text: string }) {
        return <ListItem key={text + alt} sx={{ paddingX: 0 }}><ListItemIcon sx={{ minWidth: 32 }}><img src={icon} height={32} alt={alt} /></ListItemIcon><ListItemText>{text}</ListItemText></ListItem>;
    }

    if (problemSource === "problem_count") {
        return <List dense={true} sx={{ padding: 0 }}>
            {[
                { icon: markerIconGreen, alt: "Green Icon", text: "0 critical values" },
                { icon: markerIconYellow, alt: "Yellow Icon", text: "1 critical values" },
                { icon: markerIconRed, alt: "Red Icon", text: "2 critical values" },
                { icon: markerIconDark, alt: "Black Icon", text: ">2 critical values" },
            ].map(listItem)}
        </List>;
    } else {
        return <List dense={true} sx={{ padding: 0 }}>
            {[
                { icon: markerIconGreen, alt: "Green Icon", text: "3 years or later" },
                { icon: markerIconYellow, alt: "Yellow Icon", text: "2 years" },
                { icon: markerIconRed, alt: "Red Icon", text: "next year" },
                { icon: markerIconDark, alt: "Black Icon", text: "this year" },
            ].map(listItem)}
        </List>;
    }
}

export default TurnoutMap;