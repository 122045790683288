import { loadFromApi } from "../api-client/api-client";
import { ModelParameters } from "./common";
import { ModelState, useModelState } from "./model-state";
import { PermanentModelState, usePermanentModelState } from "./permanent-model-state";

export const useModelParams = () => useModelState((state: ModelState) => state.params);
export const useModelData = () => useModelState((state: ModelState) => state.data);
export const useStoredModelParameters = () => usePermanentModelState((state) => Array.from(Object.keys(state.storedModels)).map((v) => JSON.parse(v) as ModelParameters));

export const changeParams = async (modelState: ModelState, permanentModelState: PermanentModelState, params: ModelParameters, accessToken: string) => {
    if (modelState.data.kind === "loading") { return; }

    modelState.setParams(params);
    const cachedState = permanentModelState.storedModels[JSON.stringify(params)];
    if (cachedState !== undefined) {
        modelState.setData({ kind: "successful", data: cachedState });
    } else {
        modelState.setData({ kind: "loading" });
        const response = await loadFromApi(params, accessToken);
        modelState.setData(response);
        if (response.kind === "successful") {
            permanentModelState.insertModel(params, response.data);
        }
    }
}