import { QueryStats } from "@mui/icons-material";
import { Button, FormControl, FormHelperText, Grid, Input, InputAdornment, InputLabel, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { changeParams, useModelParams } from "../state/hooks";
import { useModelState } from "../state/model-state";
import { usePermanentModelState } from "../state/permanent-model-state";
import { useOktaAuth } from "@okta/okta-react";

function CostPredictionInput({ disabled }: { disabled: boolean | undefined }) {
    const modelParameters = useModelParams();
    const modelState = useModelState();
    const permanentModelState = usePermanentModelState();

    const [inflationRate, setInflationRate] = useState(modelParameters.inflationRate);
    const [degradeRate, setDegradeRate] = useState(modelParameters.degradeRate);
    const [nOpMax, setNOpMax] = useState(modelParameters.nOpMax);
    const [maintenanceTrigger, setMaintenanceTrigger] = useState(modelParameters.maintenanceTrigger);

    const submitDisabled = JSON.stringify({ degradeRate: degradeRate, inflationRate: inflationRate, maintenanceTrigger: maintenanceTrigger, nOpMax: nOpMax }) in permanentModelState.storedModels;

    const inflationRateError = (inflationRate < 0 || inflationRate > 20) ? "must be between 0 and 20" : null;
    const degradeRateError = (degradeRate < 0 || degradeRate > 100) ? "must be between 0 and 100" : null;
    const nOpMaxError = (nOpMax !== 0 && nOpMax < 18) ? "must be 0 or ≥ 18" : null;
    const maintenanceTriggerError = (maintenanceTrigger < 0 || maintenanceTrigger > 100) ? "must be between 0 and 100" : null;

    const isError = inflationRateError !== null || degradeRateError !== null || nOpMaxError !== null || maintenanceTriggerError !== null;

    const { authState } = useOktaAuth();
    // we should be logged in at that point
    const accessToken = authState?.accessToken?.accessToken || '';

    if (inflationRate < 0) {
        setInflationRate(0);
    } else if (inflationRate > 20) {
        setInflationRate(20);
    }

    if (degradeRate < 0) {
        setDegradeRate(0);
    } else if (degradeRate > 100) {
        setDegradeRate(100);
    }

    if (maintenanceTrigger < 0) {
        setMaintenanceTrigger(0);
    } else if (maintenanceTrigger > 100) {
        setMaintenanceTrigger(100);
    }

    if (nOpMax < 0) {
        setNOpMax(0);
    }

    return <Paper elevation={0} sx={{ p: 2 }}>
        <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={6}>
                <FormControl variant="standard" fullWidth={true} error={inflationRateError !== null}>
                    <InputLabel htmlFor="inflation-rate">Inflation rate</InputLabel>
                    <Input id="inflation-rate" type="number" required endAdornment={<InputAdornment position="end">%</InputAdornment>} value={inflationRate} onChange={(event) => setInflationRate(parseFloat(event.target.value))} />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl variant="standard" fullWidth={true} error={degradeRateError !== null}>
                    <InputLabel htmlFor="degradation-rate">Degradation rate</InputLabel>
                    <Input id="degradation-rate" type="number" required endAdornment={<InputAdornment position="end">%</InputAdornment>} value={degradeRate} onChange={(event) => setDegradeRate(parseFloat(event.target.value))} />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl variant="standard" fullWidth={true} error={nOpMaxError !== null}>
                    <InputLabel htmlFor="n-op-max">max maintenance/year</InputLabel>
                    <Input id="n-op-max" type="number" aria-describedby="n-op-max-text" value={nOpMax} onChange={(event) => setNOpMax(parseFloat(event.target.value))} />
                    <FormHelperText id="n-op-max-text">{nOpMaxError || "count of operations"}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl variant="standard" fullWidth={true} error={maintenanceTriggerError !== null}>
                    <InputLabel htmlFor="maintenance-trigger">maintencance trigger</InputLabel>
                    <Input id="maintenance-trigger" type="number" aria-describedby="maintenance-trigger-text" endAdornment={<InputAdornment position="end">%</InputAdornment>} value={maintenanceTrigger} onChange={(event) => setMaintenanceTrigger(parseFloat(event.target.value))} />
                    <FormHelperText id="maintenance-trigger-text">{maintenanceTriggerError || "% of max wear"}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <Button variant="outlined" endIcon={<QueryStats />} size="large" disabled={disabled || submitDisabled || isError} onClick={async () => await changeParams(modelState, permanentModelState, { degradeRate: degradeRate, inflationRate: inflationRate, maintenanceTrigger: maintenanceTrigger, nOpMax: nOpMax }, accessToken)}>
                    Calculate
                </Button>
                <Typography variant='subtitle2' color="red">{submitDisabled ? 'results with these parameters exist already' : ''}</Typography>
            </Grid>
        </Grid>
    </Paper >
}

export default CostPredictionInput;