import { ModelParameters } from '../state/common';
import { COMPONENT_NAMES, TURNOUT_NAMES } from './static-data';

export interface SuccessfullApiState {
    kind: "successful",
    data: ApiData,
    modelParameters: ModelParameters,
}

export interface FailedApiState {
    kind: "failed",
    message: string,
}

export interface LoadingApiState {
    kind: "loading",
}

export type ApiState = SuccessfullApiState | FailedApiState | LoadingApiState;

export interface ApiData {
    /// (date, turnout, component)
    values3d: number[][][],
    /// (date)
    dates: number[],
    replacementOperations: ReplacementOperation[],
    /// (turnout, component)
    triggerSum: number[][],
    turnoutNames: string[],
    componentNames: string[],
    costData: CostData,

    latitudes: number[],
    longitudes: number[],

    /// (turnout_id, year)
    criticite_aft: number[][],
    /// (turnout_id, year)
    criticite_bef: number[][],
    /// (turnout_id, year)
    criticality_aft: number[][],
    /// (turnout_id, year)
    criticality_bef: number[][],
}

/// slice of values for a single component
export function componentSlice(apiData: ApiData, turnoutIndex: number, componentIndex: number): ComponentSlice {
    const turnoutCount = apiData.triggerSum.length;
    const componentCount = (apiData.triggerSum[0] || []).length;
    console.assert(turnoutIndex < turnoutCount, "turnout index is to large");
    console.assert(componentIndex < componentCount, "component index is to large");
    return {
        dates: apiData.dates,
        values: apiData.values3d.map((v) => v[turnoutIndex][componentIndex]),
        turnoutIndex: turnoutIndex,
        componentIndex: componentIndex,
        turnoutName: TURNOUT_NAMES[turnoutIndex],
        componentName: COMPONENT_NAMES[componentIndex]
    };
}

export function validate(apiData: ApiData): boolean {
    const dateCount = apiData.dates.length;
    const turnoutCount = apiData.triggerSum.length;
    const componentCount = (apiData.triggerSum[0] || []).length;

    if (dateCount !== apiData.values3d.length) {
        console.error("valuesAll3d has the wrong length in the time dimension");
        return false;
    }
    for (const timeSlice of apiData.values3d) {
        if (turnoutCount !== timeSlice.length) {
            console.error("valuesAll3d has the wrong length in the turnout dimension");
            return false;
        }
        for (const componentSlice of timeSlice) {
            if (componentCount !== componentSlice.length) {
                console.error("valuesAll3d has the wrong length in the component dimension");
                return false;
            }
        }
    }

    if (turnoutCount !== apiData.triggerSum.length) {
        console.error("triggerSum has the wrong length in the turnout dimension");
        return false;
    }
    for (const componentSlice of apiData.triggerSum) {
        if (componentCount !== componentSlice.length) {
            console.error("triggerSum has the wrong length in the component dimension");
            return false;
        }
    }

    if (turnoutCount !== TURNOUT_NAMES.length) {
        console.error("turnoutNames has the wrong length");
        return false;
    }

    if (componentCount !== COMPONENT_NAMES.length) {
        console.error("componentNames has the wrong length");
        return false;
    }

    return true;
}

export function getTurnoutId(apiData: ApiData, name: string): number {
    // todo this is bad
    return apiData.turnoutNames.findIndex((v) => v === name);
}

export function getComponentsForTurnout(apiData: ApiData, turnout: string): string[] {
    const turnoutId = getTurnoutId(apiData, turnout);
    if (turnoutId >= 0) {
        var validComponents = [];

        for (var p = 0; p < apiData.values3d[0][turnoutId].length; p++) {
            let valid = false;
            for (var d = 0; d < apiData.values3d.length; d++) {
                const v = apiData.values3d[d][turnoutId][p];
                if (!isNaN(v) && v > 0 && v < 10000) {
                    valid = true;
                    break;
                }
            }
            if (valid) {
                validComponents.push(apiData.componentNames[p]);
            }
        }
        return validComponents;
    } else {
        return [];
    }
}

export const loadFromApi = async (modelParameters: ModelParameters, accessToken: string): Promise<ApiState> => {
    const inflationRate = modelParameters.inflationRate;
    const degradeRate = modelParameters.degradeRate;
    const nOpMax = modelParameters.nOpMax;
    const maintenanceTrigger = modelParameters.maintenanceTrigger;
    const url = `https://j90q5ag2lf.execute-api.eu-central-1.amazonaws.com/alldata?inflation_rate=${inflationRate / 100.}&degrade_rate=${degradeRate / 100.}&n_op_max=${nOpMax}&maintenance_trigg=${maintenanceTrigger / 100.}`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
    });

    const parsedResponse = await response.json();
    //const parsedResponse = demoData;

    // the response of this API is very broken: It is a JSON document, wrongly formatted containt "NaN", which is not valid JSON,
    // and then the resulting document was again rendered as JSON
    const parsed = JSON.parse(parsedResponse
        .replace(/\bNaN\b/g, "null")
        .replace(/plot cost of maintenance operations/, 'maintenance')
        .replace(/cost by regrouping operation on same turnout at the same year./, 'grouped')
        .replace(/cost nights \(euros\)/, 'nights')
        .replace(/cost components \(euros\)/, 'components')
        .replace(/Total costs \(euros\)/, 'total')
        .replace(/Cout main d oeuvre \(euros\)/, 'nights')
        .replace(/Cout des pi\\u00e8ces \(euros\)/, 'components')
        .replace(/Cout total \(euros\)/, 'total')
        .replace(/GPS-lat/, 'gpsLongitudes')
        .replace(/GPS-lon/, 'gpsLatitudes')
    );
    console.log(parsed);

    const apiResponse = {
        values3d: parsed.predic_values.Values_all_3d,
        dates: parsed.predic_values.Dates_all,
        replacementOperations: generateReplacementOperations(parsed.predic_values.df_list_ops as DfListOps),
        triggerSum: parsed.predic_values.trigger_sum,

        turnoutNames: TURNOUT_NAMES,
        componentNames: COMPONENT_NAMES,
        costData: transformCostData(parsed.costs),

        latitudes: (parsed.criticities.gpsLongitudes as number[][]).map(v => v[0]),
        longitudes: (parsed.criticities.gpsLatitudes as number[][]).map(v => v[0]),

        criticite_aft: parsed.criticities.Criticite_aft,
        criticite_bef: parsed.criticities.Criticite_bef,
        criticality_aft: parsed.criticities.Criticity_time_aft,
        criticality_bef: parsed.criticities.Criticity_time_bef,
    }

    return {
        kind: "successful",
        data: apiResponse,
        modelParameters: modelParameters,
    }
}

export interface ComponentSlice {
    dates: number[],
    values: number[],
    turnoutIndex: number,
    componentIndex: number,
    turnoutName: string,
    componentName: string,
}
export interface CostData {
    directCostData: CostScenarioData;
    groupedCostData: CostScenarioData;
}

function transformCostData(rawData: any): CostData {
    return {
        directCostData: {
            workingCost: transformPlotData(rawData.maintenance.nights),
            materialCost: transformPlotData(rawData.maintenance.components),
            totalCost: transformPlotData(rawData.maintenance.total),
        },
        groupedCostData: {
            workingCost: transformPlotData(rawData.grouped.nights),
            materialCost: transformPlotData(rawData.grouped.components),
            totalCost: transformPlotData(rawData.grouped.total),
        },
    }
}

export interface CostScenarioData {
    workingCost: PlotData;
    materialCost: PlotData;
    totalCost: PlotData;
}

export function costScenarioMaximumValue(scenario: CostScenarioData): number {
    return Math.max(
        Math.max(...scenario.workingCost.values),
        Math.max(...scenario.materialCost.values),
        Math.max(...scenario.totalCost.values),
    )
}

export interface PlotData {
    time: number[];
    values: number[];
}

function transformPlotData(v: any): PlotData {
    return {
        time: v.y as number[],
        values: v.x as number[],
    }
}

interface DfListOps {
    cost_compo: number[],
    cost_night: number[],
    date: number[],
    jointed: (number | null)[];
    name: string[];
    operation: string[];
    rechargement_aiguille: (number | null)[];
    rechargement_coeur: (number | null)[];
    rechargement_ecartement_TO: (number | null)[];
    rechargement_orniere_porteuse: (number | null)[];
    rechargement_partie_intermediaire: (number | null)[];
    rechargement_protection_pointe: (number | null)[];
    rechargement_sortie_adv: (number | null)[];
    remplacement_aiguille: (number | null)[];
    rp: string[];
    wear_rate: number[];
    wear_rate_abs: number[];
}

function generateReplacementOperations(df_list_ops: DfListOps): ReplacementOperation[] {
    let replacementOperations: ReplacementOperation[] = [];

    df_list_ops.date.forEach((date, i) => {
        replacementOperations.push({
            date: date,
            turnout: df_list_ops.name[i].trim(),
            component: df_list_ops.rp[i].trim(),
            operation: df_list_ops.operation[i].trim(),
        });
    });

    return replacementOperations;
}

export interface ReplacementOperation {
    date: number
    turnout: string,
    component: string,
    operation: string,
}