import { Link, useLocation } from "react-router-dom";

import vosslohLogo from './vossloh-logo.svg';
import UserProfileDisplay from './UserProfileDisplay';
import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import LoginButton from "./LoginButton";


function TrackscapeAppBar() {
    const { authState } = useOktaAuth();
    const isAuthenticated = authState?.isAuthenticated;

    return <header className="ts-app-bar z-20 fixed top-0 left-0 right-0">
        <div className="ts-app-bar-left">
            <img src={vosslohLogo} alt="vossloh-logo" className="h-9" />
            <Link to={`/`}>
                <h1 className="text-2xl font-semibold text-gray-700">
                    Turnout LCC
                </h1>
            </Link>
            <ul className="flex flex-row gap-4 text-base font-bold self-center">
                <li><RouteLink destination='/model-parameters' text='Model Parameters' /></li>
                <li><RouteLink destination='/cost-prediction' text='Costs' /></li>
                <li><RouteLink destination='/turnout-map' text='Map' /></li>
                <li><RouteLink destination='/inspection-data' text='Inspection data' /></li>
            </ul>
        </div>
        <div className="ts-app-bar-right">
            {isAuthenticated ? <UserProfileDisplay /> : <LoginButton />}
        </div>
    </header>
}

function RouteLink({ destination, text }: { destination: string, text: string }) {
    let route = useLocation();

    if (route.pathname.startsWith(destination)) {
        return <Link to={destination} className="ts-app-bar-link ts-app-bar-link-active">{text}</Link>;
    } else {
        return <Link to={destination} className="ts-app-bar-link">{text}</Link>;
    }
}

export default TrackscapeAppBar;