import { Button, Grid, List, ListItemButton, Typography } from "@mui/material";
import { PermanentModelState, usePermanentModelState } from "../state/permanent-model-state";
import { changeParams, useModelParams, useStoredModelParameters } from "../state/hooks";
import { useModelState } from "../state/model-state";
import { useOktaAuth } from "@okta/okta-react";

export default function ModelParametersList({ withDelete }: { withDelete: boolean }) {
    const modelParameters = useModelParams();
    const modelState = useModelState();
    const permanentModelState = usePermanentModelState();
    const storedModelParameters = useStoredModelParameters();
    const onDelete = usePermanentModelState((state: PermanentModelState) => state.deleteModel);

    const { authState } = useOktaAuth();
    // we should be logged in at that point
    const accessToken = authState?.accessToken?.accessToken || '';

    return (
        <List>
            {storedModelParameters.map((params) => {
                const stringified = JSON.stringify(params);
                const selected = stringified === JSON.stringify(modelParameters);
                return <ListItemButton key={stringified} onClick={() => {
                    changeParams(modelState, permanentModelState, params, accessToken);
                }}
                    sx={{
                        bgcolor: selected ? '#33ae3319' : '#fdfdfd',
                        borderBottom: selected ? '1px solid #33ae33' : '1px solid #eeeeee',
                        ":hover": {
                            bgcolor: '#33ae3319', borderBottom: '1px solid #33ae33'
                        },
                        ":nth-of-type(even)": {
                            bgcolor: selected ? '#33ae3319' : '#f4f4f4',
                        },
                    }}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={withDelete ? 2 : 3}><Typography variant="caption">infl. rate</Typography><br />{params.inflationRate}</Grid>
                        <Grid item xs={withDelete ? 2 : 3}><Typography variant="caption">degr. rate</Typography><br />{params.degradeRate}</Grid>
                        <Grid item xs={withDelete ? 2 : 3}><Typography variant="caption">ops/year</Typography><br />{params.nOpMax}</Grid>
                        <Grid item xs={withDelete ? 2 : 3}><Typography variant="caption">m. trig.</Typography><br />{params.maintenanceTrigger}</Grid>
                        {withDelete && <Grid item xs={4}><Button variant="outlined" onClick={(event) => {
                            event.stopPropagation();
                            onDelete(params);
                        }}>Delete</Button></Grid>}
                    </Grid>
                </ListItemButton>
            })}
        </List>
    );
}