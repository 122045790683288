import { createTheme } from "@mui/material";

const primary = "#33AE33";
//const brandBackground = "#2A7727";

const theme = createTheme({
    palette: {
        primary: {
            main: primary,
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: 'white',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 2,
                    color: 'black',
                    '&:hover': {
                        borderColor: 'black',
                        color: primary,
                        background: 'transparent',
                    },
                    '&:active': {
                        background: primary,
                        borderColor: primary,
                        color: 'white',
                    },
                },
            },
        },
    },
    typography: {
        button: {
            fontWeight: 'bolder',
        },
        fontFamily: 'Titillium Web,Roboto Mono,Helvetica,Arial,sans-serif',
    },
});

export default theme;