import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Navigate } from 'react-router-dom';

const Protected: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const { authState } = useOktaAuth();

  if (!authState) {
   
    return <div>Error: Unable to determine authentication state.</div>;
  }

  if (authState.isPending) {
    return <div>Loading...</div>;
  }

  if (!authState.isAuthenticated) {
    return <Navigate to="/" replace />;
  }
  return children;
};

export default Protected;
