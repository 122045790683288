import { Grid, Paper, Typography } from "@mui/material";
import { ApiData, componentSlice, ComponentSlice, getTurnoutId } from "../../api-client/api-client";
import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useEffect, useState } from "react";
import { COMPONENT_LIMITS } from "../../api-client/static-data";

export function WearPredictionList({ apiData, turnoutName, year }: { apiData: ApiData, turnoutName: string, year: number }) {
    const [affectedComponents, setAffectedComponents] = useState(new Set<string>());
    useEffect(() => {
        const affected = apiData.replacementOperations.filter((operaton) => operaton.turnout === turnoutName).map((operation) => operation.component);
        setAffectedComponents(new Set(affected));
        return () => { }
    }, [apiData, turnoutName])

    const turnoutId = getTurnoutId(apiData, turnoutName || '');

    if (turnoutId >= 0) {
        let turnoutParameters = Array.from(Array(apiData.componentNames.length).keys())
            .map((paramIndex) => componentSlice(apiData, turnoutId, paramIndex))
            .filter((parameterData) => parameterData.values.some((value) => value !== null && !isNaN(value) && value !== 1000000));

        return <>
            {
                affectedComponents.size !== 0 && <>
                    <Typography variant="h5" sx={{ p: 1, textAlign: "center" }}>Componets with planned maintenance</Typography>
                    <Grid container spacing={2}>
                        {turnoutParameters.filter((data) => affectedComponents.has(data.componentName)).map((data) => {
                            const maintenanceYears = apiData.replacementOperations
                                .filter((operation) => operation.turnout === turnoutName && operation.component === data.componentName)
                                .map((operation) => operation.date);
                            return (
                                <Grid key={data.componentIndex} item sm={12} lg={6}>
                                    <ComponentGraph data={data} year={year} maintenanceYears={maintenanceYears} />
                                </Grid>
                            );
                        })
                        }
                    </Grid >
                </>
            }
            <Typography variant="h5" sx={{ p: 1, textAlign: "center" }}>Components without planned maintenance</Typography>
            <Grid container spacing={2}>
                {turnoutParameters.filter((data) => !affectedComponents.has(data.componentName)).map((data) => {
                    const maintenanceYears = apiData.replacementOperations
                        .filter((operation) => operation.turnout === turnoutName && operation.component === data.componentName)
                        .map((operation) => operation.date);
                    return (
                        <Grid key={data.componentIndex} item sm={12} lg={6}>
                            <ComponentGraph data={data} year={year} maintenanceYears={maintenanceYears} />
                        </Grid>
                    );
                })
                }
            </Grid >
        </>
    } else {
        return <p>Error</p>
    }
}

function ComponentGraph({ data, year, maintenanceYears }: { data: ComponentSlice, year: number, maintenanceYears: number[] }) {
    const transformedData = data.dates.map((time, i) => {
        return {
            time: time, value: (data.values[i] || 0.0).toFixed(3)
        };
    });

    const limits = COMPONENT_LIMITS[data.componentName] || { min: NaN, max: NaN };

    var minValue = Math.min(...data.values, limits.min);
    var maxValue = Math.max(...data.values, limits.max);

    return (
        <Paper elevation={0} sx={{ mt: 1 }}>
            <Typography variant="h6" sx={{ p: 1, textAlign: "center" }}>{data.componentName}</Typography>
            <ResponsiveContainer height={300}>
                <LineChart data={transformedData}>
                    <CartesianGrid stroke="1" />
                    <XAxis
                        dataKey="time"
                        allowDuplicatedCategory={true}
                        type="number"
                        domain={['dataMin', 'dataMax']}
                        ticks={data.dates.filter(v => v % 5 === 0).filter(onlyUnique)}
                        interval="equidistantPreserveStart"
                    />
                    <YAxis
                        domain={[Math.floor(minValue), Math.ceil(maxValue)]}
                        tickFormatter={v => v.toFixed(1)}
                        interval={"preserveStartEnd"}
                        type="number"
                    />
                    <ReferenceLine x={year} stroke="#b0daae" />
                    {maintenanceYears.map((year) => <ReferenceLine key={year} x={year} stroke="#63b6c9" label={`${year}`} />)}
                    {!isNaN(limits.min) && <ReferenceLine y={limits.min} stroke="#ffaf00" />}
                    {!isNaN(limits.max) && <ReferenceLine y={limits.max} stroke="#ffaf00" />}
                    <Tooltip />
                    <Legend />
                    <Line type="linear" dataKey="value" stroke="#33AE33" dot={false} strokeWidth={2} animationDuration={250} />
                </LineChart>
            </ResponsiveContainer>
        </Paper>
    );
}

function onlyUnique(value: any, index: number, array: any[]) {
    return array.indexOf(value) === index;
}